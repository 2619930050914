import React from 'react';
import { Route } from 'react-router-dom';
import { VisitListener } from '@backstage/plugin-home';
import { ApiExplorerPage } from '@backstage/plugin-api-docs';
import {
  CatalogEntityPage,
  CatalogIndexPage,
  catalogPlugin,
  CatalogTable,
  CatalogTableColumnsFunc,
  CatalogTableRow,
} from '@backstage/plugin-catalog';
import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { orgPlugin } from '@backstage/plugin-org';
import { SearchPage } from '@backstage/plugin-search';
import { TechRadarPage } from '@backstage-community/plugin-tech-radar';
import {
  TechDocsIndexPage,
  techdocsPlugin,
  TechDocsReaderPage,
} from '@backstage/plugin-techdocs';
import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import { ReportIssue } from '@backstage/plugin-techdocs-module-addons-contrib';
import { UserSettingsPage } from '@backstage/plugin-user-settings';
import { apis } from './apis';
import { entityPage } from './components/catalog/EntityPage';
import { searchPage } from './components/search/SearchPage';
import { ExplorePage } from '@backstage-community/plugin-explore';
import { Root } from './components/Root';
import { HomepageCompositionRoot } from '@backstage/plugin-home';
import { HomePage } from './components/home/HomePage';
import {
  AlertDisplay,
  OAuthRequestDialog,
  TableColumn,
} from '@backstage/core-components';
import { createApp } from '@backstage/app-defaults';
import { AppRouter, FlatRoutes } from '@backstage/core-app-api';
import { CatalogGraphPage } from '@backstage/plugin-catalog-graph';

import { microsoftAuthApiRef } from '@backstage/core-plugin-api';
import { SignInPage } from '@backstage/core-components';

import { CustomRenderNode } from './components/ewiiComponents/catalog/CustomRenderNode';
import { defaultKinds } from './components/ewiiComponents/catalog/defaultKinds';
import { createReleaseInfoColumn } from './components/ewiiComponents/catalog/CustomColumns';

import { Ewii } from './components/ewiiComponents/theme/EwiiTheme';
import { UnifiedThemeProvider } from '@backstage/theme';
import { CssBaseline } from '@material-ui/core';

const columns: CatalogTableColumnsFunc = entityListContext => {
  if (entityListContext.filters.kind?.value === 'component') {
    return [
      CatalogTable.columns.createNameColumn(),
      CatalogTable.columns.createSystemColumn(),
      // CatalogTable.columns.createOwnerColumn(),
      createReleaseInfoColumn(),
      CatalogTable.columns.createSpecLifecycleColumn(),
      CatalogTable.columns.createTagsColumn(),
    ];
  }

  if (entityListContext.filters.kind?.value === 'api') {
    return [
      CatalogTable.columns.createNameColumn(),
      // CatalogTable.columns.createOwnerColumn(),
      CatalogTable.columns.createSpecTypeColumn(),
      CatalogTable.columns.createSpecLifecycleColumn(),
      CatalogTable.columns.createTagsColumn(),
    ];
  }

  if (entityListContext.filters.kind?.value === 'resource') {
    return [
      CatalogTable.columns.createNameColumn(),
      // CatalogTable.columns.createOwnerColumn(),
      CatalogTable.columns.createSpecTypeColumn(),
      CatalogTable.columns.createSpecLifecycleColumn(),
    ];
  }

  return CatalogTable.defaultColumnsFunc(entityListContext);
};

const apiColumns: TableColumn<CatalogTableRow>[] = [
  CatalogTable.columns.createTitleColumn({ hidden: true }),
  CatalogTable.columns.createNameColumn({ defaultKind: 'API' }),
  // CatalogTable.columns.createOwnerColumn(),
  CatalogTable.columns.createSpecTypeColumn(),
  CatalogTable.columns.createSpecLifecycleColumn(),
  CatalogTable.columns.createTagsColumn(),
];

const app = createApp({
  apis,
  themes: [
    {
      id: 'ewii',
      title: 'Ewii',
      variant: 'dark',
      Provider: ({ children }) => (
        <UnifiedThemeProvider theme={Ewii} noCssBaseline>
          <CssBaseline />
          {children}
        </UnifiedThemeProvider>
      ),
    },
  ],
  components: {
    SignInPage: props => (
      <SignInPage
        {...props}
        auto
        provider={{
          id: 'microsoft-auth-provider',
          title: 'Microsoft',
          message: 'Sign in using Microsoft',
          apiRef: microsoftAuthApiRef,
        }}
      />
    ),
  },
  bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      createComponent: scaffolderPlugin.routes.root,
      viewTechDoc: techdocsPlugin.routes.docRoot,
    });
    // bind(apiDocsPlugin.externalRoutes, {
    //   registerApi: catalogImportPlugin.routes.importPage,
    // });
    // bind(scaffolderPlugin.externalRoutes, {
    //   registerComponent: catalogImportPlugin.routes.importPage,
    // });
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
  },
});

const routes = (
  <FlatRoutes>
    <Route path="/" element={<HomepageCompositionRoot />}>
      <HomePage />
    </Route>
    <Route path="/catalog" element={<CatalogIndexPage columns={columns} />} />
    <Route
      path="/catalog/:namespace/:kind/:name"
      element={<CatalogEntityPage />}
    >
      {entityPage}
    </Route>
    <Route path="/docs" element={<TechDocsIndexPage />} />
    <Route
      path="/docs/:namespace/:kind/:name/*"
      element={<TechDocsReaderPage />}
    >
      <TechDocsAddons>
        <ReportIssue />
      </TechDocsAddons>
    </Route>
    {/* <Route path="/create" element={<ScaffolderPage />} /> */}
    <Route
      path="/api-docs"
      element={<ApiExplorerPage columns={apiColumns} />}
    />
    <Route
      path="/tech-radar"
      element={<TechRadarPage width={1500} height={800} />}
    />
    {/* <Route
      path="/catalog-import"
      element={
        <RequirePermission permission={catalogEntityCreatePermission}>
          <CatalogImportPage />
        </RequirePermission>
      }
    /> */}
    <Route path="/search" element={<SearchPage />}>
      {searchPage}
    </Route>
    <Route path="/settings" element={<UserSettingsPage />} />
    <Route
      path="/catalog-graph"
      element={
        <CatalogGraphPage kinds={defaultKinds} renderNode={CustomRenderNode} />
      }
    />
    <Route path="/explore" element={<ExplorePage />} />
  </FlatRoutes>
);

export default app.createRoot(
  <>
    <AlertDisplay />
    <OAuthRequestDialog />
    <AppRouter>
      <VisitListener />
      <Root>{routes}</Root>
    </AppRouter>
  </>,
);
